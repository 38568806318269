import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { useNavigate, Link, useParams } from 'react-router-dom';
import MDBox from "components/MDBox";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { 

  TextField,

 IconButton,
 InputAdornment
  
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { CardActionArea, Skeleton, Pagination } from '@mui/material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import book_img from "assets/images/book_thumb.png"

import { getToken } from 'hooks/TokenManager';


const apiUrl = 'https://api.nishkamkarmyogimasik.com/api/bookListByUser';


function AllotedBook() {
  const navigate = useNavigate();
  const {userId}=useParams()
  const [apiData, setApiData] = useState([]);
  const [bookdata,setBookList]=useState([])
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
 
  const itemsPerPage = 8;
    
  const fetchData = async () => {
    const bodyData = {
      userId: userId,
    };
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': getToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData), // stringify the request body
      });
  
      const data = await response.json();
      console.log(data.msg);
  
      if (data.status) {
        setApiData(data.msg);
        setBookList(data.msg);
      } else {
        console.error('API request failed:', data.msg);
      }
    } catch (error) {
      localStorage.removeItem("token")
      navigate("/authentication/sign-in")
      console.error('Error fetching API:', error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  

  useEffect(() => {
    fetchData();
  }, []);

 

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = apiData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleQueryChange = (e) => {
    const query = e.target.value.trim().toLowerCase();
    const bookList = bookdata.filter((book) => {
      const book_no = book.book_no.toString(); // Convert to string
      return book_no.includes(query);
    });
    setApiData(bookList);
  };
  


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
      <TextField
    
    onChange={handleQueryChange}
    placeholder="Enter Book No"
    type='number'
    sx={{marginBottom:"1rem"}}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <IconButton
            sx={{ paddingLeft: "0px" }}
            aria-label="search icon"
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
        <MDBox mb={2}>
          <Grid container spacing={3}>
            {loading ? (
              // Loading Skeleton
              Array.from({ length: 8 }).map((_, index) => (
                <Grid item xs={6} xl={3} key={index}>
                  <Skeleton variant="rectangular" width="100%" height={200} />
                </Grid>
              ))
            ) : (
              // Actual Content
              currentItems.map((dataItem) => (
                <Grid item xs={6} xl={3} key={dataItem.id}>
                  <Link to={`/receipt/book_no/${dataItem.book_no}`}>
                    <Card>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          sx={{
                            width: '88%',
                            height: 'auto',
                          }}
                          image={book_img}
                          alt={dataItem.book_no}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" sx={{ textAlign: "center" }} component="div">
                            पुस्तक {dataItem.book_no}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
              ))
            )}
          </Grid>
        </MDBox>

        {/* Pagination */}
        <Pagination
          count={Math.ceil(apiData.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color='warning'
        />
      </MDBox>
   
    </DashboardLayout>
  );
}

export default AllotedBook;
