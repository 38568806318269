import React,{useState,useEffect} from 'react'
import { getToken } from 'hooks/TokenManager'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField'
import Card from "@mui/material/Card";
import MDAlert from "components/MDAlert";
import MDSnackbar from 'components/MDSnackbar'
import { MaharashtraDistricts } from 'utils/maharastraData'
import { MenuItem } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom';
const apiUrl = 'https://api.nishkamkarmyogimasik.com/api/receiptById';

const RecieptBook = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fname, setFname]=useState('')
  const [mname, setMname]=useState('')
  const [lname, setLname]=useState('')
  const [mobileno, setMobile]=useState('')
  const [district, setDistrict]=useState('')
  const [taluka, setTaluka]=useState('')
  const [village, setVillage]=useState('')
  const [pincode, setPincode]=useState('')
  const [mashik, setMashik]=useState('')
  const [occupation, setOccupation]=useState('')
  const [bookno,setBookno]=useState('')
  const [receiptno,setReceiptno]=useState('')
  const [sabhasad,setSabhasad]=useState('')
  const [username,setUsername]=useState('')
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Book is not assigned"
      content="There is no book assigned for any user with this receipt number"
      
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': getToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ receipt_id: id }),
      });

      const data = await response.json();
      console.log(data.msg);

      if (data.status) {
       if(data.msg[0].userId===0){
        setErrorSB(true);
        setReceiptno(data.msg[0].id)
       }else{
        setReceiptno(data.msg[0].id)
        setFname(data.msg[0].fname)
        setMname(data.msg[0].mname)
        
        setLname(data.msg[0].lname)
        setMobile(data.msg[0].mobileno)
        setDistrict(data.msg[0].district)
        setTaluka(data.msg[0].taluka)
        setVillage(data.msg[0].village)
        setPincode(data.msg[0].pincode)
        setMashik(data.msg[0].masik)
        setOccupation(data.msg[0].occupation)
        setUsername(data.msg[0].name)
        setSabhasad(data.msg[0].userId)
        setBookno(data.msg[0].book_id)
       }
      
      } else {
        console.error('API request failed:', data.msg);
      }
    } catch (error) {
      localStorage.removeItem("token")
      navigate("/authentication/sign-in")
      console.error('Error fetching API:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlechangeReceiptNo = (e) => {
    setReceiptno((prevValue) => {
      // Extract the entered value from the event
      const inputValue = e.target.value;
  
      // Use a regular expression to check if the entered value is a number
      const isValidNumber = /^\d*$/.test(inputValue);
  
      // If it's a valid number, update the state and navigate to the new route
      if (isValidNumber) {
        fetchData()
        navigate(`/signle-receipt/${inputValue}`);
        
        return inputValue;
      } else {
        // If it's not a valid number, keep the previous value
        return prevValue;
      }
    });
  };
  
  
  
  return (
    <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
        <Card >
        <MDBox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={5}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          नोंदणी पावती नं.
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {id}
          </MDTypography>
        </MDBox>
        {renderErrorSB}
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
          <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="Book No" variant="standard" value={bookno} fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="नोंदणी पावती नं." variant="standard" value={receiptno} onChange={handlechangeReceiptNo}   fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="सभासद नं." variant="standard" value={sabhasad}  fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="कुणी जोडले" variant="standard" value={username}  fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="नाव " variant="standard" value={fname} onChange={(e)=>setFname(e.target.value)} fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="वडिलांचे नाव " variant="standard" value={mname} onChange={(e)=>setMname(e.target.value)} fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="आडनाव " variant="standard" value={lname} onChange={(e)=>setLname(e.target.value)} fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="मोबाईल नं." variant="standard" value={mobileno} onChange={(e)=>setMobile(e.target.value)} fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="राज्य " variant="standard" value="महाराष्ट्र" fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <TextField 
                  type="text" 
                  label="जिल्हा" 
                  variant="standard"  
                  fullWidth
                  value={district}
                  onChange={(e)=>setDistrict(e.target.value)}
                  select
                  sx={{mt:1}}
                    >
                      {MaharashtraDistricts.map((option, i)=>{
                        return(
                          <MenuItem key={i} value={option.name}>{option.name}</MenuItem>
                        )
                      })}
                  </TextField>
               </MDBox>
               
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <TextField 
                  type="text" 
                  label="तालुका" 
                  variant="standard"  
                  fullWidth
                  value={taluka}
                  onChange={(e)=>setTaluka(e.target.value)}
                  select
                  sx={{mt:1}}
                    >
                      {MaharashtraDistricts.map((option)=>
                       option.name === district ? (
                        option.tahasil.map((tahasil) => (
                          <MenuItem key={tahasil} value={tahasil}>{tahasil}</MenuItem>
                        ))
                       ):null
                      )}
                  </TextField>
               </MDBox>
               
               </Grid>
              
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="पत्ता " variant="standard" value={village} onChange={(e)=>setVillage(e.target.value)} fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="पिन कोड" variant="standard" value={pincode} onChange={(e)=>setPincode(e.target.value)} fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="मासिक" variant="standard" value={mashik} fullWidth  />
               </MDBox>
               </Grid>
               <Grid item xs={12} lg={4}>
               <MDBox mb={2}>
                  <MDInput type="text" label="व्यवसाय" value={occupation} onChange={(e)=>setOccupation(e.target.value)} variant="standard" fullWidth  />
               </MDBox>
               </Grid>
              
          </Grid>
        
       
            <MDBox mt={4} mb={1} sx={{display:"flex", justifyContent:"center"}}>
              <MDButton variant="gradient" color="warning"   >
                Update Receipt
              </MDButton>
            </MDBox>
            
          </MDBox>
        </MDBox>
      </Card>
      </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default RecieptBook