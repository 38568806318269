import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { useNavigate, Link } from 'react-router-dom';
import MDBox from "components/MDBox";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  
  Fab,
  Tooltip,
  TextField,
 
  Button,
 IconButton,
 InputAdornment
  
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { CardActionArea, Skeleton, Pagination } from '@mui/material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import book_img from "assets/images/book_thumb.png"
import Swal from 'sweetalert2';
import { getToken } from 'hooks/TokenManager';
import axios from 'axios';

const apiUrl = 'https://api.nishkamkarmyogimasik.com/api/bookList';
const addBooksApiUrl = 'https://api.nishkamkarmyogimasik.com/api/addBooks';

function Billing() {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [bookdata,setBookList]=useState([])
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfBooks, setNumberOfBooks] = React.useState('');
  const itemsPerPage = 8;

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': getToken(),
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log(data.msg);

      if (data.status) {
        setApiData(data.msg);
        setBookList(data.msg)
      } else {
        console.error('API request failed:', data.msg);
      }
    } catch (error) {
      localStorage.removeItem("token")
        navigate("/authentication/sign-in")
      console.error('Error fetching API:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = apiData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleQueryChange = (e) => {
    const query = e.target.value.trim().toLowerCase();
    const bookList = bookdata.filter((book) => {
      const book_no = book.book_no.toString(); // Convert to string
      return book_no.includes(query);
    });
    setApiData(bookList);
  };
  
  const handleAddBooks = async () => {
    try {
      const response = await axios.post(addBooksApiUrl, {
        numberOfBook: numberOfBooks,
      }, {
        headers: {
          'Authorization': getToken(),
          'Content-Type': 'application/json',
        },
      });

      if (response.data.status) {
        // Display success message
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.msg,
        });

        // Refresh the book list
        fetchData();

        // Clear the input field
        setNumberOfBooks('');

        // Close the dialog
        handleClose();
      } else {
        // Display error message
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.msg,
        });
      }
    } catch (error) {
      // Display generic error message
      console.error('Error adding books:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Internal Server Error',
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
      <TextField
    
    onChange={handleQueryChange}
    placeholder="Enter Book No"
    type='number'
    sx={{marginBottom:"1rem"}}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <IconButton
            sx={{ paddingLeft: "0px" }}
            aria-label="search icon"
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
        <MDBox mb={2}>
          <Grid container spacing={3}>
            {loading ? (
              // Loading Skeleton
              Array.from({ length: 8 }).map((_, index) => (
                <Grid item xs={6} xl={3} key={index}>
                  <Skeleton variant="rectangular" width="100%" height={200} />
                </Grid>
              ))
            ) : (
              // Actual Content
              currentItems.map((dataItem) => (
                <Grid item xs={6} xl={3} key={dataItem.id}>
                  <Link to={`/receipt/book_no/${dataItem.book_no}`}>
                    <Card>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          sx={{
                            width: '88%',
                            height: 'auto',
                          }}
                          image={book_img}
                          alt={dataItem.book_no}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" sx={{ textAlign: "center" }} component="div">
                            पुस्तक {dataItem.book_no}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
              ))
            )}
          </Grid>
        </MDBox>

        {/* Pagination */}
        <Pagination
          count={Math.ceil(apiData.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color='warning'
        />
      </MDBox>
      <Tooltip title="Add Books" placement="bottom">
          <Fab size="large"
            color="primary"
            aria-label="add"
            sx={{
              margin: "0px",
              top: "auto",
              right: "30px",
              bottom: "30px",
              left: "auto",
              position: "fixed"
             
            }}
            onClick={handleClickOpen}
            
          >
            <AddIcon />
          </Fab>
          </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Enter the number of books"}
        </DialogTitle>
        <DialogContent >
          <div style={{display:"flex", justifyContent:"center", marginBottom:"1rem"}}>
          <img src={book_img} alt='jaibaba ji book' style={{height:"100px"}} />
          </div>
          <TextField
            type='number'
            placeholder='Enter number of books '
            fullWidth
            value={numberOfBooks}
            onChange={(e) => setNumberOfBooks(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={handleClose}>Cancel</Button>
          <Button  autoFocus  onClick={handleAddBooks}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Billing;
