/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import theme from "assets/theme";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Formik } from "formik";
import * as Yup from 'yup';
import axios from "axios";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import TempleBuddhistIcon from '@mui/icons-material/TempleBuddhist';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import useScriptRef from "hooks/useScriptRef";
import { useNavigate } from 'react-router';
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Button, FormHelperText } from "@mui/material";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// ... (previous imports)

function Basic({ ...others }) {
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [responseError, setResponseError] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const validateForm = () => {
    let valid = true;

    if (!email) {
      setEmailError("Email is required.");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required.");
      valid = false;
    } else {
      setPasswordError("");
    }

    return valid;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post('https://api.nishkamkarmyogimasik.com/api/adminLogin', {
        email,
        password,
      });

      console.log(response.data);

      if (response.data.status && response.data.msg) {
        localStorage.setItem('token', response.data.msg);
        navigate('/dashboard');
      } else {
        console.error('Login failed:', response);
        setResponseError("Login failed. Please check your credentials.");
      }
    } catch (err) {
      console.error('API call failed:', err);
      setResponseError("An error occurred while logging in.");
    }
  };

  const isLoggedIn =()=>{
    if(!localStorage.getItem('token')){
      navigate('/authentication/sign-in')
    }else{
      navigate('/dashboard')
    }
  }
  useEffect(() => {
  isLoggedIn()
  },[])


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                {/* <FacebookIcon color="inherit" /> */}
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <TempleBuddhistIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                {/* <GoogleIcon color="inherit" /> */}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
            <MDInput
                type="email"
                label="Email"
                fullWidth
                error={Boolean(emailError)}
                sx={{ ...theme.typography.customInput }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <FormHelperText error id="standard-weight-helper-text-email-login">
                  {emailError}
                </FormHelperText>
              )}
            </MDBox>
            <MDBox mb={2}>
            <MDInput
                type="password"
                label="Password"
                fullWidth
                error={Boolean(passwordError)}
                sx={{ ...theme.typography.customInput }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <FormHelperText error id="standard-weight-helper-text-password-login">
                  {passwordError}
                </FormHelperText>
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            {responseError && (
              <MDBox sx={{ mt: 3 }}>
                <FormHelperText error>{responseError}</FormHelperText>
              </MDBox>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="warning"
                fullWidth
                disableElevation
                type="submit"
                onClick={handleFormSubmit}
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}



export default Basic;
