import { useState } from "react";
import {
  Grid,
  Card,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fab, 
  Tooltip
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AddIcon from '@mui/icons-material/Add';
import { getToken } from "hooks/TokenManager";
// Replace this import with your actual data fetching logic
import notificatList from './notificationList';

function Notifications() {
  const { columns, rows } = notificatList();
  const [openAddNotificationDialog, setOpenAddNotificationDialog] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationDescription, setNotificationDescription] = useState('');

  const handleOpenAddNotificationDialog = () => {
    setOpenAddNotificationDialog(true);
  };

  const handleCloseAddNotificationDialog = () => {
    setOpenAddNotificationDialog(false);
  };

  const handleAddNotification = async () => {
    try {
      // Make the API call to add a notification
      const response = await fetch("https://api.nishkamkarmyogimasik.com/api/addNotification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        body: JSON.stringify({
          title: notificationTitle,
          description: notificationDescription,
        }),
      });

      // Parse the JSON response
      const result = await response.json();

      // Check if the notification was added successfully
      if (result.status) {
        // Implement your logic here if the notification is added successfully
        // ...

        // After adding the notification, close the dialog
        handleCloseAddNotificationDialog();
      } else {
        // Handle the case where the notification was not added successfully
        console.error("Error adding notification:", result.msg);
        // You can optionally show an error message to the user
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error adding notification:", error);
      // You can optionally show an error message to the user
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card>
              <MDBox pt={3}>
                <DataTable table={{ columns, rows }} isSorted={true} entriesPerPage={true} showTotalEntries={true} noEndBorder />
              </MDBox>
            </Card>
          </Grid>


        </Grid>
      </MDBox>
      <Tooltip title="Add New Notification" placement="bottom">
        <Fab
          size="large"
          color="primary"
          aria-label="add"
          sx={{
            margin: '0px',
            top: 'auto',
            right: '30px',
            bottom: '30px',
            left: 'auto',
            position: 'fixed',
          }}
          onClick={handleOpenAddNotificationDialog}
          
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <Dialog
        open={openAddNotificationDialog}
        onClose={handleCloseAddNotificationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Notification</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="notificationTitle"
            label="Notification Title"
            type="text"
            fullWidth
            value={notificationTitle}
            onChange={(e) => setNotificationTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            id="notificationDescription"
            label="Notification Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={notificationDescription}
            onChange={(e) => setNotificationDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddNotificationDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddNotification} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Notifications;
