export const  MaharashtraDistricts = [
  {
    name: "अहमदनगर",
    tahasil: [
      "अकोला",
      "जामखेड़",
      "कर्जत",
      "कोपरगांव",
      "नगर",
      "नेवासा",
      "पर्नेर",
      "पथर्डी",
      "राहता",
      "राहुरी",
      "संगमनेर",
      "शेवगांव",
      "श्रीगोंडा",
      "श्रीरामपुर"
    ]
  },
  {
    name: "अकोला",
    tahasil: [
      "अकोला",
      "अकोट",
      "बालापुर",
      "बर्शीतकली",
      "मुर्तिजापुर",
      "पाटूर",
      "टेलहारा"
    ]
  },
  {
    name: "अमरावती",
    tahasil: [
      "अचलपुर",
      "अमरावती",
      "अंजनगांव सुरजी",
      "भाटकुली",
      "चंदुर रेलवे",
      "चंदुरबाजार",
      "चिखलदारा",
      "दर्यापुर",
      "धामांगांव रेलवे",
      "धरणी",
      "मोर्शी",
      "नंदगांव-खांडेश्वर",
      "टेओसा",
      "वारुड"
    ]
  },
  {
    name: "छ. संभाजीनगर",
    tahasil: [
      "छ. संभाजीनगर",
      "गंगापुर",
      "कन्नड",
      "खुलदाबाद",
      "पैठण",
      "फुलंब्री",
      "सिल्लोड़",
      "सोयगाव",
      "वैजापूर"
    ]
  },
  {
    name: "बीड",
    tahasil: [
      "अंबेजोगाई",
      "अश्ती",
      "बीड",
      "धारूर",
      "गेवाराई",
      "कईज",
      "मंजलेगांव",
      "परली",
      "पटोड़ा",
      "शिरूर (कसर)",
      "वडवानी"
    ]
  },
  {
    name: "भंडारा",
    tahasil: [
      "भंडारा",
      "मोहाड़ी",
      "पाऊनी",
      "तुमसार"
    ]
  },
  {
    name: "बुलढाणा",
    tahasil: [
      "बुलढाणा",
      "चिखली",
      "देवलगांव राजा",
      "जलगांव (जमोद)",
      "खामगांव",
      "लोणार",
      "मलकापुर",
      "मेहकर",
      "मोटाला",
      "नंदूरा",
      "संगरामपुर",
      "शेगांव",
      "सिंधखेड़ राजा"
    ]
  },
  {
    name: "चंद्रपूर",
    tahasil: [
      "बल्लारपुर",
      "भद्रावती",
      "ब्रह्मापुरी",
      "चंद्रपूर",
      "चिमूर",
      "गोंदपिपरी",
      "जिवती",
      "कोरपाना",
      "मूल",
      "नागभिर",
      "पोंभूरणा",
      "राजुरा",
      "सवाली",
      "सिंडेवाही",
      "वारोड़ा"
    ]
  },
  {
    name: "धुले",
    tahasil: [
      "धुले",
      "साकरी",
      "शिरपूर",
      "सिंधखेड़े"
    ]
  },
  {
    name: "गडचिरोली",
    tahasil: [
      "अहेरी",
      "आर्मोरी",
      "भामरागड़",
      "चमोर्शी",
      "देसाइगंज (वडासा)",
      "धनोरा",
      "एतापल्ली",
      "गडचिरोली",
      "कोरची",
      "कुरखेड़ा",
      "मुलचेरा",
      "सिरोंचा"
    ]
  },
  {
    name: "गोंदिया",
    tahasil: [
      "अमगाँव",
      "अर्जुनी मोरगाँव",
      "देवरी",
      "गोंदिया",
      "गोरेगाँव",
      "सड़क-अर्जुनी",
      "सलेकासा",
      "तिरोरा"
    ]
  },
  {
    name: "हिंगोली",
    tahasil: [
      "औंधा (नागनाथ)",
      "बासमठ",
      "हिंगोली",
      "कलमनुरी",
      "सेंगांव"
    ]
  },
  {
    name: "जळगाँव",
    tahasil: [
      "अमलनेर",
      "भादगांव",
      "भुसावल",
      "बोडवड",
      "चाळीसगांव",
      "चोपड़ा",
      "धरणगांव",
      "इरंडोल",
      "जळगाँव",
      "जामनेर",
      "मुक्तैनगर",
      "पचोरा",
      "परोळा",
      "रावेर",
      "यावळ"
    ]
  },
  {
    name: "जालना",
    tahasil: [
      "अंबाड",
      "बडनापूर",
      "भोकरदान",
      "घांसावंगी",
      "जाफराबाद",
      "जालना",
      "मंथा",
      "पारतूर"
    ]
  },
  {
    name: "कोल्हापूर",
    tahasil: [
      "अजरा",
      "बवडा",
      "भुदरगड़",
      "चंदगड़",
      "गढ़िंगलाज",
      "हाटकनांगले",
      "कगल",
      "करवीर",
      "पन्हाळा",
      "राधानगरी",
      "शाहुवाडी",
      "शिरोळ"
    ]
  },
  {
    name: "लातूर",
    tahasil: [
      "अहमदपुर",
      "औसा",
      "चाकुर",
      "देओनी",
      "जलकोट",
      "लातूर",
      "निलांगा",
      "रेणापुर",
      "शिरूर-अनंतपाल",
      "उदगीर"
    ]
  },
  {
    name: "मुंबई उपनगर",
    tahasil: [
      "अंधेरी",
      "बोरीवली",
      "कुर्ला"
    ]
  },
  {
    name: "नागपुर",
    tahasil: [
      "भिवापुर",
      "हिंगना",
      "कालामेश्वर",
      "कामप्टी",
      "काटोल",
      "कूही",
      "मौदा",
      "नागपुर (ग्रामीण)",
      "नागपुर (शहरी)",
      "नारखेड़",
      "पारसेओनी",
      "रामटेक",
      "सावनेर",
      "उमरेड"
    ]
  },
  {
    name: "नांदेड़",
    tahasil: [
      "अरधापुर",
      "भोकर",
      "बिलोली",
      "देगलूर",
      "धर्माबाद",
      "हडगांव",
      "हिमायतनगर",
      "कांधर",
      "किंवत",
      "लोहा",
      "महूर",
      "मुदखेड़",
      "मुखेड़",
      "नायगाँव (खैरगाँव)",
      "नांदेड़",
      "उमरी"
    ]
  },
  {
    name: "नंदुरबार",
    tahasil: [
      "अक्कलकुवा",
      "अकरणी",
      "नंदुरबार",
      "नवापुर",
      "शहाड़े",
      "तालोड़े"
    ]
  },
  {
    name: "नासिक",
    tahasil: [
      "बागलान",
      "चंदवड़",
      "देओला",
      "दिंडोरी",
      "इगतपुरी",
      "कलवान",
      "मालेगांव",
      "नंदगांव",
      "नासिक",
      "निफड़",
      "पैंट",
      "सिनार",
      "सुर्गाणा",
      "त्रिम्बकेश्वर",
      "येवला"
    ]
  },
  {
    name: "उस्मानाबाद",
    tahasil: [
      "भूम",
      "कालंब",
      "लोहारा",
      "उस्मानाबाद",
      "परंडा",
      "तुळजापुर",
      "उमरगा",
      "वाशी"
    ]
  },
  {
    name: "परभणी",
    tahasil: [
      "गंगाखेड़",
      "जिंटूर",
      "मनवाठ",
      "पालम",
      "परभणी",
      "पाठरी",
      "पुरणा",
      "सैलू",
      "सोनेपठ"
    ]
  },
  {
    name: "पुणे",
    tahasil: [
      "अंबेगाव",
      "बारामती",
      "भोर",
      "दौंड",
      "हवेली",
      "इंदापूर",
      "जुन्नर",
      "खेड",
      "मावळ",
      "मुळशी",
      "पुणे शहर",
      "पुरंधर",
      "शिरूर",
      "वेल्हे"
    ]
  },
  {
    name: "रायगड",
    tahasil: [
      "अलिबाग",
      "कर्जत",
      "खालापूर",
      "महाड",
      "मंगाओन",
      "म्हासला",
      "मुरुड",
      "पनवेल",
      "पेण",
      "पोलादपूर",
      "रोहा",
      "श्रीवर्धन",
      "सुधागड",
      "ताळा",
      "उरण"
    ]
  },
  {
    name: "रत्नागिरी",
    tahasil: [
      "चिपळूण",
      "दापोली",
      "गुहागर",
      "खेड",
      "लांजा",
      "मांडणगड",
      "राजापूर",
      "रत्नागिरी",
      "सांगमेश्वर"
    ]
  },
  {
    name: "सांगली",
    tahasil: [
      "आतपडी",
      "जात",
      "कडेगांव",
      "कवठेमहंकल",
      "खानापूर",
      "मिरज",
      "पालूस",
      "शिराळा",
      "तासगांव",
      "वळवा"
    ]
  },
  {
    name: "सतारा",
    tahasil: [
      "जाओली",
      "कराड",
      "खंडाळा",
      "खताव",
      "कोरेगाव",
      "महाबळेश्वर",
      "मन",
      "पातण",
      "फळतान",
      "सतारा",
      "वाई"
    ]
  },
  {
    name: "सिंधुदुर्ग",
    tahasil: [
      "देवगड",
      "डोडमार्ग",
      "कांकवली",
      "कुडाळ",
      "मालवण",
      "सावंतवाडी",
      "वैभववाडी",
      "वेंगुर्ला"
    ]
  },
  {
    name: "सोलापूर",
    tahasil: [
      "अक्कलकोट",
      "बारशी",
      "करमाला",
      "मधा",
      "माळशीरस",
      "मंगळवेढे",
      "मोहोळ",
      "पांड़पूर",
      "सांगोळ",
      "सोलापूर उत्तर",
      "सोलापूर दक्षिण"
    ]
  },
  {
    name: "ठाणे",
    tahasil: [
      "अंबरनाथ",
      "भिवंडी",
      "दहाणू",
      "जव्हार",
      "कल्याण",
      "मोखाडा",
      "मुरबाड",
      "पालघर",
      "शहापूर",
      "तालासारी",
      "ठाणे",
      "उल्हासनगर",
      "वडा",
      "वसई",
      "विक्रमगड"
    ]
  },
  {
    name: "वाशीम",
    tahasil: [
      "अरवी",
      "आश्ती",
      "देवली",
      "हिंगंगट",
      "करंजा",
      "सामुद्रपूर",
      "सेलू",
      "वर्धा"
    ]
  },
  {
    name: "वाशीम",
    tahasil: [
      "करंजा",
      "मालेगांव",
      "मांग्रुलपीर",
      "मनोरा",
      "रिसोड",
      "वाशीम"
    ]
  },
  {
    name: "यवतमाळ",
    tahasil: [
      "अरनी",
      "बाबुलगांव",
      "दारवा",
      "दिग्रास",
      "घाटांजी",
      "कळंब",
      "केळापूर",
      "महागाव",
      "मारेगाव",
      "नेर",
      "पुसड",
      "राळेगांव",
      "उमरखेड",
      "वाणी",
      "यवतमाळ",
      "ज़री-जमनी"
    ]
  }
];