import React from 'react'
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import { useParams } from 'react-router-dom'
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
const EditUser = () => {
    const {id, name}=useParams()

  return (
    <DashboardLayout>
        <DashboardNavbar />
        <MDBox>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={6}>
        <Card >
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={5}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Edit
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {name}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
          <MDBox mb={2}>
              <MDInput type="text" label="Username" variant="standard" fullWidth value={name} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="number" label="Mobile" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Village Name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" variant="standard" fullWidth />
            </MDBox>
        
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth>
                Update user
              </MDButton>
            </MDBox>
          
          </MDBox>
        </MDBox>
      </Card>
      </Grid>
      </Grid>
        </MDBox>
    </DashboardLayout>
  )
}

export default EditUser