import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { getToken } from "hooks/TokenManager";
import { Skeleton } from '@mui/material';
import { Link, useNavigate} from 'react-router-dom';
// Images
import team2 from "assets/images/anonymous-avatar-icon-25.jpg";
const apiUrl = 'https://api.nishkamkarmyogimasik.com/api/usersList';
function useApiData() {
  const navigate = useNavigate()
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Authorization': getToken(),
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        console.log(data.msg);

        if (data.status) {
          setApiData(data.msg);
        } else {
          localStorage.removeItem("token")
          navigate("/authentication/sign-in")
          console.error('API request failed:', data.msg);
        }
      } catch (error) {
        localStorage.removeItem("token")
        navigate("/authentication/sign-in")
        console.error('Error fetching API:', error);
      } finally {
        
        // Set loading to false regardless of success or failure
        
        setLoading(false);
        
      }
    };

    fetchData();
  }, []);

  return { apiData, loading };
}
export default function Data() {
  const { apiData, loading } = useApiData();
  const formatDate = dateString => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
    
    
  };
  const renderSkeletonRow = () => (
    Array.from({ length: 5 }).map((_, index) => (
      {
        name: <Skeleton key={`name-${index}`} variant="text" width={100} height={20} />,
        village: <Skeleton key={`village-${index}`} variant="text" width={100} height={20} />,
        username: <Skeleton key={`username-${index}`} variant="text" width={100} height={20} />,
        mobile: <Skeleton key={`mobile-${index}`} variant="text" width={80} height={20} />,
        password: <Skeleton key={`password-${index}`} variant="text" width={80} height={20} />,
        status: <Skeleton key={`status-${index}`} variant="text" width={80} height={20} />,
        created: <Skeleton key={`created-${index}`} variant="text" width={70} height={20} />,
        action: <Skeleton key={`action-${index}`} variant="text" width={35} height={20} />,
      }
    ))
  );

  const Author = ({ image, name, email }) => (
    
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  if (loading) {
    // Display loading skeleton while data is being fetched
    return {
      columns: [
        { Header: "name", accessor: "name",  align: "left" },
        { Header: "username", accessor: "username", align: "center" },
        { Header: "mobile No.", accessor: "mobile", align: "center" },
        { Header: "password", accessor: "password", align: "center" },
        { Header: "village", accessor: "village", align: "left" },
  
        { Header: "status", accessor: "status", align: "center" },
        { Header: "created on", accessor: "created", align: "center" },
        { Header: "action", accessor: "action", align: "center" },
      ],
  
      rows: renderSkeletonRow(),
    };
  
    
  }



  return {
    columns: [
      { Header: "name", accessor: "name",  align: "left" },
      { Header: "username", accessor: "username", align: "center" },
      { Header: "mobile No.", accessor: "mobile", align: "center" },
      { Header: "password", accessor: "password", align: "center" },
      { Header: "village", accessor: "village", align: "left" },

      { Header: "status", accessor: "status", align: "center" },
      { Header: "created on", accessor: "created", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

     rows: apiData.map((rowData) => ({
      // Map API response data to your rows
      name: <Author image={team2} name={rowData.name} />,
      village: rowData.village===null?(
        <MDBox ml={-1}>
        <MDBadge badgeContent="blank" color="error" variant="gradient" size="sm" />
      </MDBox>
      ):(<><Job title={rowData.village || ''} /></>) ,
      username: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {rowData.username}
        </MDTypography>
      ),
      mobile: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {rowData.mobile}
        </MDTypography>
      ),
      password: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {rowData.password}
        </MDTypography>
      ),
      status: rowData.status===1?(  <MDBox ml={-1}>
        <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
      </MDBox>):(
          <MDBox ml={-1}>
          <MDBadge badgeContent="Blocked" color="error" variant="gradient" size="sm" />
        </MDBox>
      )
      ,
      created: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {formatDate(rowData.created_at)}
        </MDTypography>
      ),
      action: (
        <>
        <Tooltip title="Block/Unblock user" placement="bottom"><Switch /></Tooltip>
        <Tooltip title="Edit/view user" placement="bottom"><IconButton sx={{ verticalAlign: "middle" }}  aria-label="view" color='secondary' as={Link} to={`/edit/${rowData.username}/${rowData.id}`}><EditIcon /></IconButton></Tooltip>
        </>
        
      ),
    })),
  };
  
}
