import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getToken } from "hooks/TokenManager";
import { Skeleton } from '@mui/material';
import { Link, useNavigate} from 'react-router-dom';
import MDProgress from "components/MDProgress";
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import DeleteIcon from '@mui/icons-material/Delete';
// Images
import team2 from "assets/images/anonymous-avatar-icon-25.jpg";
import Swal from 'sweetalert2';
const apiUrl = 'https://api.nishkamkarmyogimasik.com/api/notificationList';
function useApiData() {
  const navigate = useNavigate()
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Authorization': getToken(),
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        console.log(data.msg);

        if (data.status) {
          setApiData(data.msg);
        } else {
          console.error('API request failed:', data.msg);
        }
      } catch (error) {
        localStorage.removeItem("token")
        navigate("/authentication/sign-in")
        console.error('Error fetching API:', error);
      } finally {
        
        // Set loading to false regardless of success or failure
        
        setLoading(false);
        
      }
    };

    fetchData();
  }, []);

  return { apiData, loading };
}
export default function Data() {
  const viewNote =(title, description)=>{
    Swal.fire({
      html:`<h6>${title}</h6><br><hr><br><p>${description}</p>`
    })
  }
  const { apiData, loading } = useApiData();
  const formatDate = dateString => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
    
    
  };
  const renderSkeletonRow = () => (
    Array.from({ length: 5 }).map((_, index) => (
      {
        name: <Skeleton key={`name-${index}`} variant="text" width={500} height={20} />,
        action: <Skeleton key={`action-${index}`} variant="text" width={50} height={20} />,
        
        
      }
    ))
  );

  const Author = ({ image, name, email }) => (
    
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  if (loading) {
    // Display loading skeleton while data is being fetched
    return {
      columns: [
        { Header: "title", accessor: "name",  align: "left" },
        
        { Header: "action", accessor: "action", align: "center" },
      ],
  
      rows: renderSkeletonRow(),
    };
  
    
  }
  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );


  return {
    columns: [
      { Header: "name", accessor: "title",  align: "left" },
  
      { Header: "action", accessor: "action", align: "center" },
    ],

     rows: apiData.map((rowData) => ({
      // Map API response data to your rows   
      title: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {rowData.title}
        </MDTypography>
      ),
    
      action: (
        <>
        <Tooltip title="View Notification" placement="bottom"><IconButton sx={{ verticalAlign: "middle" }}  aria-label="view" color='secondary' onClick={()=>viewNote(rowData.title,rowData.description)}><VisibilityIcon /></IconButton></Tooltip>
        <Tooltip title="Delete Notification" placement="bottom"><IconButton sx={{ verticalAlign: "middle" }}  aria-label="delete" color='error'><DeleteIcon /></IconButton></Tooltip>
        </>
        
      ),
    })),
  };
  
}
