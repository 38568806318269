import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { getToken } from "hooks/TokenManager";
import { Skeleton } from '@mui/material';
import { Link, useNavigate} from 'react-router-dom';
import MDProgress from "components/MDProgress";
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
// Images
import team2 from "assets/images/anonymous-avatar-icon-25.jpg";
const apiUrl = 'https://api.nishkamkarmyogimasik.com/api/usersListIithCount';
function useApiData() {
  const navigate = useNavigate()
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Authorization': getToken(),
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        console.log(data.msg);

        if (data.status) {
          setApiData(data.msg);
        } else {
          localStorage.removeItem("token")
          navigate("/authentication/sign-in");
          console.error('API request failed:', data.msg);
        }
      } catch (error) {
        localStorage.removeItem("token")
         navigate("/authentication/sign-in")
        console.error('Error fetching API:', error);
      } finally {
      
        
        setLoading(false);
        
      }
    };

    fetchData();
  }, []);

  return { apiData, loading };
}
export default function Data() {
  const { apiData, loading } = useApiData();
  const formatDate = dateString => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
    
    
  };
  const renderSkeletonRow = () => (
    Array.from({ length: 5 }).map((_, index) => (
      {
        name: <Skeleton key={`name-${index}`} variant="text" width={100} height={20} />,
        
        mobile: <Skeleton key={`mobile-${index}`} variant="text" width={80} height={20} />,
        books: <Skeleton key={`books-${index}`} variant="text" width={80} height={20} />,
        completion: <Skeleton key={`completion-${index}`} variant="text" width={80} height={20} />,
        action: <Skeleton key={`action-${index}`} variant="text" width={35} height={20} />,
      }
    ))
  );

  const Author = ({ image, name, email }) => (
    
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  if (loading) {
    // Display loading skeleton while data is being fetched
    return {
      columns: [
        { Header: "name", accessor: "name",  align: "left" },
        
        { Header: "mobile No.", accessor: "mobile", align: "center" },
        { Header: "Books", accessor: "books", align: "center" },
        { Header: "completion", accessor: "completion", align: "center" },
        { Header: "action", accessor: "action", align: "center" },
      ],
  
      rows: renderSkeletonRow(),
    };
  
    
  }
  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );


  return {
    columns: [
      { Header: "name", accessor: "name",  align: "left" },
     
      { Header: "mobile No.", accessor: "mobile", align: "center" },
      { Header: "Books", accessor: "books", align: "center" },
      { Header: "completion", accessor: "completion", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

     rows: apiData.map((rowData) => ({
      // Map API response data to your rows
      name: <Author image={team2} name={rowData.name} />,
   
      mobile: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {rowData.mobile}
        </MDTypography>
      ),
      books: <Tooltip title={`${rowData.name}ला ${rowData.book_count} पुस्तकांचे वाटप करण्यात आले आहे`} placement="bottom"><IconButton color='warning' size='small' as={Link} to={`/allocated/user-id/${rowData.id}`} >{rowData.book_count} पुस्तके</IconButton></Tooltip>,
      completion: <Progress color="error" value={rowData.receipt_count/20*100} />,
      action: (
        <>
        <Tooltip title="Edit/view user" placement="bottom"><IconButton sx={{ verticalAlign: "middle" }}  aria-label="view" color='secondary' ><EditIcon /></IconButton></Tooltip>
        </>
        
      ),
    })),
  };
  
}
