import React, { useState } from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import { Grid, Card, Button, Typography, Checkbox } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup'; // Import the Backup icon
import MDButton from 'components/MDButton';
const Backup = () => {
    const [checkdb,setCheckDb]=useState(true)
    const [files, setFiles]=useState(false)
    const handleCheckDb=()=>{
        setCheckDb(!checkdb)
    }
    const handleCheckedFile=()=>{
        setFiles(!files)
    }
    const handleDownload = (filename) => {
        // Create an anchor element
        const link = document.createElement('a');
        link.href = `./backup/${filename}`; // assuming 'backup' is a folder in the public directory
        link.download = filename;
    
        // Trigger a click on the anchor element to start the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
        // Store download date-time in local storage
        const now = new Date();
        localStorage.setItem(filename, now.toISOString());
      };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={6}>
            {/* Database Backup */}
            <Card>
              <MDBox p={2}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox checked={checkdb} onClick={handleCheckDb} /> {/* Checked checkbox for database backup */}
                  <Typography variant="h6" style={{ marginLeft: '8px' }}>
                    Database Backup
                  </Typography>
                  <BackupIcon style={{ marginLeft: '8px' }} /> {/* Database backup icon */}
                </div>
                {/* Add content for database backup */}
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            {/* Files Backup */}
            <Card>
              <MDBox p={2}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox checked={files} onClick={handleCheckedFile} /> {/* Unchecked checkbox for files backup */}
                  <Typography variant="h6" style={{ marginLeft: '8px' }}>
                    Files Backup
                  </Typography>
                  <BackupIcon style={{ marginLeft: '8px' }} /> {/* Files backup icon */}
                </div>
                {/* Add content for files backup */}
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} lg={10}>
            {/* Center Image */}
            <Card>
              <MDBox p={2}>
                <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                  <img src="images/5322156.png" alt="5322156" height="200" />
                </div>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} lg={10}>
            {/* Last Backup Information */}
            <Card>
              <MDBox p={2}>
                <Typography variant="h6" textAlign="center">Last Backup Information</Typography>
                <Typography variant="body2" textAlign="center">{localStorage.getItem('backup.zip')}</Typography>
              </MDBox>
              <div style={{display:"flex", justifyContent:"center", marginTop:"0.5rem", marginBottom:"0.5rem"}}>
                <MDButton variant="gradient" color="primary" onClick={() => handleDownload('backup.zip')}>
                  Backup Now
                </MDButton>
              </div>
            </Card>
          </Grid>


        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Backup;
