import React, { useState, useEffect } from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { getToken } from 'hooks/TokenManager';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import {
  Grid,
  Card,
  Typography,
  Fab,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  Autocomplete
} from '@mui/material';
import userBookManager from '../tables/data/userBookManager';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import AddIcon from '@mui/icons-material/Add';
import book_img from 'assets/images/book_thumb.png';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
const apiUrlUsersList = 'https://api.nishkamkarmyogimasik.com/api/usersList';
const apiUrlAllocateBook = 'https://api.nishkamkarmyogimasik.com/api/allocateBook';
const apiUrlBookList='https://api.nishkamkarmyogimasik.com/api/bookList';

const BooksManager = () => {
  const navigate = useNavigate()
  const { columns, rows } = userBookManager();
  const [open, setOpen] = React.useState(false);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(0);
  const [bookId, setBookId] = useState(0);
  const [bookList, setBookList] = useState([]);
  const [filteredBookList, setFilteredBookList] = useState([]);

  


  const fetchBookList = async () => {
    try {
      const response = await fetch(apiUrlBookList, {
        method: 'POST',
        headers: {
          'Authorization': getToken(),
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.status) {
        setBookList(data.msg);
        setFilteredBookList(data.msg)
      } else {
        
        console.error('API request failed:', data.msg);
      }
    } catch (error) {
      localStorage.removeItem("token")
      navigate("/authentication/sign-in")
      console.error('Error fetching API:', error);
    } finally {
      setLoading(false);
    }
  };

  

  const allocateBook = async () => {
    try {
      const response = await fetch(apiUrlAllocateBook, {
        method: 'POST',
        headers: {
          'Authorization': getToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          bookId: bookId,
        }),
      });

      const data = await response.json();

      if (data.status) {
        // Allocation successful, you may want to show a success message or refresh the data
        console.log('Book allocated successfully');
        Swal.fire({
          icon:"success",
          text:data.msg
      })
        handleClose();
        fetchData(); // Refresh data after allocation
        userBookManager()
      } else {
        handleClose();
        Swal.fire({
            icon:"warning",
            text:data.msg
        })
    
      
        console.error('API request failed:', data.msg);
        // Handle error, show error message, etc.
      }
    } catch (error) {
      localStorage.removeItem("token")
      navigate("/authentication/sign-in")
      console.error('Error allocating book:', error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrlUsersList, {
        method: 'POST',
        headers: {
          'Authorization': getToken(),
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.status) {
        setApiData(data.msg);
      } else {
        console.error('API request failed:', data.msg);
      }
    } catch (error) {
      console.error('Error fetching API:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchBookList();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleBookSearchChange = (event, value) => {
    setBookId(value?.id || 0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="warning"
              >
                <MDTypography variant="h6" color="white">
                  वापरकर्ते
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable table={{ columns, rows }} isSorted={true} entriesPerPage={true} showTotalEntries={true} noEndBorder />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Tooltip title="Allocate new book to user" placement="bottom">
        <Fab
          size="large"
          color="primary"
          aria-label="add"
          sx={{
            margin: '0px',
            top: 'auto',
            right: '30px',
            bottom: '30px',
            left: 'auto',
            position: 'fixed',
          }}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Allocate a new book</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
            <img src={book_img} alt="jaibaba ji book" style={{ height: '150px', width: '280px' }} />
          </div>
          <TextField
            select
            variant="standard"
            label="Select user"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="Select user"
            fullWidth
          >
            <MenuItem value={0}>Select</MenuItem>
            {apiData.map((users) => {
              return (
                <MenuItem key={users.id} value={users.id}>
                  {users.name} - ({users.mobile})
                </MenuItem>
              );
            })}
          </TextField>

          <Autocomplete
            options={filteredBookList}
            getOptionLabel={(option) => `पुस्तक ${option.id}`}
            onChange={handleBookSearchChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Book"
                variant="standard"
                fullWidth
                sx={{ mt: 3 }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button autoFocus onClick={allocateBook}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default BooksManager;
