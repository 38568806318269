import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import { getToken } from 'hooks/TokenManager';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
const apiUrl = 'https://api.nishkamkarmyogimasik.com/api/receiptByBookId';

const Receipt = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': getToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ book_id: id }),
      });

      const data = await response.json();
      console.log(data.msg);

      if (data.status) {
        setApiData(data.msg);
      } else {
        console.error('API request failed:', data.msg);
      }
    } catch (error) {
      localStorage.removeItem("token")
      navigate("/authentication/sign-in")
      console.error('Error fetching API:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getCardColor = (userData) => {
    // Check conditions for red card
    if (
      userData.userId === null ||
      userData.fname === null ||
      userData.mname === null ||
      userData.lname === null ||
      userData.mobileno === null ||
      userData.district === null ||
      userData.taluka === null ||
      userData.village === null ||
      userData.pincode === null ||
      userData.masik === '' ||
      userData.occupation === null
    ) {
      return 'red'; // You can change this to the desired color class
    } else {
      return 'green'; // You can change this to the desired color class
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={4}>
          {
          loading ? (
            // Loading Skeleton
            Array.from({ length: 20 }).map((_, index) => (
              <Grid item xs={6} xl={3} key={index}>
                <Skeleton variant="rectangular" width="100%" height={70} />
              </Grid>
            ))
          ) :(
          apiData.map((userData) => (
            <Grid item xs={12} xl={3} key={userData.id}>
                <Link to={`/signle-receipt/${userData.id}`}>
              <Card sx={{ backgroundColor: getCardColor(userData),height:"70px" }}>
                <CardActionArea>
                <CardContent>
                  <Typography variant="h5" component="div" textAlign="center" mt={1} sx={{color:"#fff"}}>
                    Receipt {userData.id}
                  </Typography>
                </CardContent>
                </CardActionArea>
              </Card>
              </Link>
            </Grid>
          )))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Receipt;
