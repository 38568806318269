import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { getToken } from 'hooks/TokenManager';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const AddUsers = () => {
  const navigate= useNavigate()
  const [userData, setUserData] = useState({
    name: '',
    username: '',
    mobile: '',
    village: '',
    password: '',
  });

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.nishkamkarmyogimasik.com/api/addUsers', userData, {
        headers: {
          'Authorization': getToken(),
          'Content-Type': 'application/json',
        },
      });

      if (response.data.status) {
        // Display success message
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.msg,
        });
        navigate("/users")

        // Clear form data
        setUserData({
          name: '',
          username: '',
          mobile: '',
          village: '',
          password: '',
        });
      } else {
        // Display error message
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.msg,
        });
      }
    } catch (error) {
      // Display generic error message
      console.error('Error adding user:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Internal Server Error',
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={6}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={2}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                सेवक तयार करा
                </MDTypography>
               
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <form onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      name="name"
                      label="सेवकाचे पूर्ण नाव "
                      variant="standard"
                      fullWidth
                      value={userData.name}
                      onChange={handleChange}
                    />
                  </MDBox>
                  {/* <MDBox mb={2}>
                    <MDInput
                      type="text"
                      name="username"
                      label="Username"
                      variant="standard"
                      fullWidth
                      value={userData.username}
                      onChange={handleChange}
                    />
                  </MDBox> */}
                  <MDBox mb={2}>
                    <MDInput
                      type="number"
                      name="mobile"
                      label="मोबाईल नंबर"
                      variant="standard"
                      fullWidth
                      value={userData.mobile}
                      onChange={handleChange}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      name="village"
                      label="सेवकाचा पत्ता"
                      variant="standard"
                      fullWidth
                      value={userData.village}
                      onChange={handleChange}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      name="password"
                      label="पासवर्ड"
                      variant="standard"
                      fullWidth
                      value={userData.password}
                      onChange={handleChange}
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="warning" fullWidth type="submit">
                    सेवक बनवा
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default AddUsers;
